
const et = {
  Booking: 'Broneerimine',
  'Go back': 'Mine tagasi',
  'By making a booking, you agree to have read and understood our Terms of Use and Privacy Policy': 'Broneeringut tehes nõustud, et oled lugenud ja tutvunud meie kasutajatingimuste ja privaatsuspoliitikaga.',
  Location: 'Asukoht',
  'Select location': 'Valige asukoht',
  'Select the location you wish to book at': 'Valige asukoht, kuhu soovite broneeringut teha',
  Service: 'Teenus',
  'The minimum time of booking is hours hours!': 'Minimaalselt saab broneerida {hours} mängutundi!',
  Lane: 'Rada',
  Table: 'Laud',
  Hour: 'Tund',
  Hours: 'Tundi',
  'NB! An advance payment of €50 is required for the packages.': 'NB! Pakettide tellimisel tuleb tasuda ettemaks 50€.',
  Packages: 'Paketid',
  Package: 'Pakett',
  Quantity: 'Kogus',
  'Number of people': 'Inimeste arv',
  'Select a package': 'Vali pakett',
  'What is included in the package?': 'Mida pakett sisaldab?',
  'Select a service': 'Vali teenus',
  Date: 'Kuupäev',
  Time: 'Kellaaeg',
  'Select a date to see availability!': 'Vali kuupäev, et näha saadavust!',
  'There are no times available on this day.': 'Valitud kuupäeval vabu aegu ei ole.',
  'Previous day': 'Eelmine päev',
  'Next day':  'Järgmine päev',
  'Select a time': 'Vali aeg',
  Contacts: 'Kontaktandmed',
  'First name and family name': 'Ees- ja perekonnanimi',
  'E-mail address': 'E-posti aadress',
  Phone: 'Telefon',
  'Gift card': 'Kinkekaart',
  'I have a gift card': 'Mul on kinkekaart või Stebby pilet',
  'Make sure the gift card is valid at the time of your booking. Validity, dates, etc. can be found on the gift card.': 'Veendu, et kinkekaart kehtib broneeringu ajal. Kehtivuse, kuupäevad jms leiad kinkekaardilt.',
  'Discount code': 'Sooduskood',
  'I have a discount code': 'Mul on sooduskood',
  'Please check the discount code.': 'Palun kontrolli sooduskoodi.',
  'The discount code is valid': 'Sooduskood {code} kehtib.',
  'The gift card code is added': 'Kinkekaart {code} on lisatud.',
  'The gift card codes are added': "Kinkekaardid {code} on lisatud.",
  'GIFTCARD_USED': "Kinkekaart on juba kasutatud.",
  'GIFTCARD_EXPIRED': "Kinkekaart on aegunud.",
  'GIFTCARD_HAS_BOOKING': "Kinkekaardiga on juba broneering tehtud.",
  'GIFTCARD_NOT_VALID_ON_REQUESTED_DATE': "Kinkekaart ei kehti soovitud ajal.",
  'GIFTCARD_NOT_VALID_AT_REQUESTED_TIME': "Kinkekaart ei kehti soovitud kellaajal.",
  'GIFTCARD_NOT_VALID_WITH_REQUESTED_SERVICE': "Kinkekaarti ei saa soovitud teenusega kasutada.",
  'GIFTCARD_NOT_VALID_AT_REQUESTED_LOCATION': "Kinkekaarti ei saa soovitud asukohas kasutada.",
  'GIFTCARD_NOT_FOUND': 'Palun kontrolli kinkekaardi koodi ja veendu, et broneering vastaks kinkekaardil toodud tingimustele.',
  'You have already made a booking in the last hour!': 'Viimase tunni jooksul on selle e-mailiga juba broneering tehtud!',
  Check: 'Kontrolli',
  Add: 'Lisa',
  'Enter the gift card code': 'Sisesta kinkekaardi kood',
  'Enter the discount code': 'Sisesta sooduskood',
  'I have a business or partner code': 'Mul on ärikliendi/partneri kood',
  'Enter your partner code': 'Sisesta ärikliendi/partneri kood',
  'Please check the partner code.': 'Palun kontrolli partneri koodi.',
  'The partner code is valid': 'Partneri kood on lisatud',
  'Additional info': 'Lisainformatsioon',
  'An advance payment of €50 is required to book a package.': 'Paketi broneerimiseks on vaja tasuda ettemaks 50€.',
  'terms and conditions': 'üld- ja ostutingimustega',
  'I agree with the general terms and conditions of purchase': 'Nõustun <a href=\'' + 'https://kuulsaal.ee/meist/broneerimistingimused/' + '\' target=\'_blank\'>' + 'üld- ja ostutingimustega' + '</a>',
  'Book': 'Broneeri',
  'Confirm the booking': 'Kinnita broneering',
  'Redirecting to payment page, please wait': 'Suundun edasi maksma, palun oota',
  'Thank you for your booking!': 'Täname broneeringu eest!',
  'A booking confirmation has been sent to your e-mail address.': 'Broneeringut kinnitav e-mail on saadetud ka Teie e-mailile',
  'Back to homepage': 'Tagasi kodulehele',
  'Are you sure you wish to cancel your booking?': 'Kas oled kindel, et soovid broneeringu tühistada?',
  'Order number:': 'Broneeringu number:',
  'No, do not cancel': 'Ei, aitäh',
  'Yes, cancel booking': 'Jah, palun tühistada',
  'The booking has been cancelled.': 'Broneering on tühistatud',
  'Your booking has been cancelled. If you have made an advance payment and there is more than 72 hours until your booking, the refund will be made to your bank account as soon as possible.': 'Sinu broneering on tühistatud. Kui tegid broneeringule ettemakse ning broneeringuni on rohkem kui 72 tundi, tehakse tagasimakse Teie kontole esimesel võimalusel.',
  'There was a problem with the payment!': 'Maksega on probleem!',
  'Your payment could not be processed. If you think this was a mistake, please contact our customer support.': 'Sinu makse ei õnnestunud.<br/>Kui arvad, et tegu on veaga, palun pöördu meie <a target="_blank" href="https://kuulsaal.ee/kontakt/kesklinn/">klienditoe poole</a>.',
  'terms of use': 'kasutustingimusi',
  'privacy policy': 'privaatsuspoliitikat',
  'By booking the time you agree that you have read and got acquainted with us': 'Broneerides aja nõustute, et olete lugenud meie <a href=\'' + 'https://kuulsaal.ee/meist/broneerimistingimused/' + '\' target=\'_blank\'>kasutustingimusi</a> ja <a href=\'' + 'https://kuulsaal.ee/meist/privaatsuspoliitika/' + '\' target=\'_blank\'>privaatsuspoliitikat</a>.',
  'If you wish to pay under the warranty, please contact us to make a reservation.': 'Kui soovite maksta garantiikirja alusel, võtke broneeringu tegemiseks meiega ühendust.',
  'If you want to book a larger number of people with an event than the booking system is shown, please contact us!': 'Kui sa soovid broneerida suuremat inimeste arvuga sündmust kui broneerimissüsteemis näidatud on, palun võta meiega ühendust!',
  'hours': 'tundi',
  'Kuulsaal - Reservation': 'Kuulsaal - Broneerimine'
}
export default et;